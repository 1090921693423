@font-face {
    font-family: 'RussianRail';
    font-weight: normal;
    font-style: normal;
    src: url('../public/fonts/RussianRail_G_Regular.otf');
}

@font-face {
    font-family: 'FSRail';
    font-weight: normal;
    font-style: normal;
    src: url('../public/fonts/FSRAIL55.otf');
}

@font-face {
    font-family: 'FSRail Thin';
    font-weight: normal;
    font-style: normal;
    src: url('../public/fonts/FSRAIL55.otf');
}

.closingDiv {
    display: none;
    width: 100vw;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
}

.modal {
    justify-content: center;
    align-items: center;
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
}

.content {
    margin-right: 40px;
    margin-left: 40px;
}

.photoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 518px;
    height: 346px;
}

.photo {
    max-width: 518px;
    max-height: 346px;
    object-fit: contain;
    margin-bottom: 20px;
}

p {
    font-family: 'FSRail', serif;
    font-size: 17px;
    color: #7e7e7e;
    line-height: 26px;
    margin: 0 0 15px;
}

h3,
h4 {
    font-weight: 500;
    margin: 20px 0 30px;
    font-family: 'RussianRail', serif;
    font-size: 18px;
    text-transform: uppercase;
}

.modal-content {
    top: 0;
    margin-top: 30px;
    margin-bottom: 60px;
    border-radius: 10px;
    flex-direction: column;
    align-items: center;
    position: absolute;
    display: flex;
    background-color: #fefefe;
    padding: 20px 20px 0 20px;
    max-width: 600px;
    width: 100%;
    z-index: 2;
    box-shadow: 0 5px 17px -10px #3c2d2d;
}

.photoSlider {
    display: flex;
    margin-top: 20px;
}

.photoSlider span {
    border-radius: 10px;
    display: block;
    width: 6px;
    height: 6px;
    border: 2px solid #000;
}

.photoSlider span:not(:last-child) {
    margin-right: 10px;
}

.none {
    display: none;
}

.checked {
    background-color: #000;
}

.photoSlider span:hover {
    background-color: #000;
}

.close {
    z-index: 2;
    display: none;
    align-items: center;
    align-self: flex-end;
    max-width: 518px;
    margin-top: 10px;
    margin-bottom: 23px;
}

.close button {
    outline: none;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
}

.cross {
    width: 20px;
}

.play {
    width: 32px;
}

@media (max-width: 800px) {
    p {
        margin: 0 0 25px;
    }

    .photoSlider {
        margin-top: 0;
    }

    .photo {
        height: 246px;
        max-width: 300px;
        width: 100%;
    }

    .photoContainer {
        width: 270px;
        height: 246px;
    }

    .content {
        margin-right: 0;
        margin-left: 0;
    }

    .close {
        padding: 5px;
        margin-top: 15px;
        margin-bottom: 5px;
    }

    .modal-content {
        width: 80%;
        margin-bottom: 0;
        padding: 0 20px 0 20px;
        margin-top: 0;
        top: 80px;
    }

    .modal {
        overflow-x: auto;
        height: 100vh;
        top: 0;
    }
}
